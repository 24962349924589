import { fonts } from '../fonts'

const commonStyles = {
	minHeight: '40px',
	minWidth: 'fit-content',
	borderRadius: '0px',
	textTransform: 'lowercase',
	fontWeight: 400,
	fontSize: 'var(--button-primary-size)',
	lineHeight: 'var(--button-primary-size)',
	letterSpacing: '2px',
	padding: '14px 21px 12px',
	boxShadow: 'none',
	borderWidth: '1px',
	textAlign: 'center',
	transition: 'all var(--transition)',
	'&:hover': {
		borderWidth: '1px',
		borderColor: 'currentColor',
	},
	'&:focus': {
		borderWidth: '1px',
		borderColor: 'currentColor',
	},
	'&:active': {
		borderWidth: '1px',
		borderColor: 'currentColor',
	},
	'&.Mui-disabled': {
		borderWidth: '1px',
	},
	'& .MuiButton-startIcon': {
		fill: 'currentColor',
		marginLeft: 0,
		marginRight: '8px',
		marginTop: '-5px',
	},
	'& .MuiSvgIcon-root': {},
	'& .MuiButton-endIcon': {
		fill: 'currentColor',
		marginLeft: '8px',
		marginRight: 0,
		display: 'none',
	},
}

const Button = {
	MuiButton: {
		styleOverrides: {
			root: {
				...fonts.helveticaNeueLTStd,
				...commonStyles,
				'&.MuiLink-underlineNone': {
					textDecoration: 'none',
				},
			},
			contained: {
				minWidth: 'unset',
			},
			containedPrimary: {
				...commonStyles,
				color: 'var(--color-secondary)',
				background: 'var(--color-primary)',
				'&:hover': {
					color: 'var(--color-primary)',
					background: 'var(--color-secondary)',
				},
				'&:focus': {
					color: 'var(--color-primary)',
					background: 'var(--color-secondary)',
				},
				'&:active': {
					color: 'var(--color-primary)',
					background: 'var(--color-secondary)',
				},
				'&.Mui-disabled': {
					background: 'var(--color-grey70)',
				},
			},
			containedSecondary: {
				...commonStyles,
				border: '1px solid var(--color-primary)',
				color: 'var(--color-primary)',
				background: 'var(--color-secondary)',
				'&:hover': {
					border: '1px solid var(--color-primary)',

					color: 'var(--color-secondary)',
					background: 'var(--color-primary)',
				},
				'&:focus': {
					color: 'var(--color-secondary)',
					background: 'var(--color-primary)',
				},
				'&:active': {
					color: 'var(--color-secondary)',
					background: 'var(--color-primary)',
				},
				'&.Mui-disabled': {
					borderColor: 'var(--color-grey70)',
					background: 'var(--color-grey70)',
					color: 'var(--color-grey60)',
				},
			},
			outlined: {
				...commonStyles,
			},
			outlinedPrimary: {
				color: 'var(--color-primary)',
				borderColor: 'var(--color-primary)',
				backgroundColor: 'transparent',
				'&:hover': {
					backgroundColor: 'var(--color-primary)',
					color: 'var(--color-secondary)',
					borderColor: 'var(--color-primary)',
				},
				'&:focus': {
					backgroundColor: 'var(--color-primary)',
				},
				'&:active': {
					backgroundColor: 'var(--color-primary)',
				},
				'&.Mui-disabled': {
					color: 'var(--color-grey60)',
					background: 'var(--color-grey70)',
				},
			},
			outlinedSecondary: {
				color: 'var(--color-secondary)',
				borderColor: 'var(--color-secondary)',
				backgroundColor: 'transparent',
				'&:hover': {
					backgroundColor: 'var(--color-primary)',
					color: 'var(--color-secondary)',
					borderColor: 'var(--color-primary)',
				},
				'&:focus': {
					backgroundColor: 'var(--color-primary)',
					color: 'var(--color-secondary)',
					borderColor: 'var(--color-primary)',
				},
				'&:active': {
					backgroundColor: 'var(--color-primary)',
					color: 'var(--color-secondary)',
					borderColor: 'var(--color-primary)',
				},
				'&.Mui-disabled': {
					color: 'var(--color-grey60)',
					background: 'var(--color-grey70)',
				},
			},
			text: {
				...commonStyles,
				padding: '7px 2px 5px',
				minWidth: 'unset',
				minHeight: '40px',
				borderWidth: '0',
				borderRadius: '0px',
				lineHeight: 'var(--size-small)',
				position: 'relative',
				overflow: 'hidden',
				'&::after': {
					content: `""`,
					display: 'block',
					position: 'absolute',
					left: 0,
					right: 0,
					top: 0,
					bottom: 0,
					zIndex: '-1',
					background: 'transparent',
					transform: 'translateY(100%) translateY(-1px)',
					transition: 'all var(--transition)',
				},
				'&:hover': {
					'&::after': {
						transform: 'translateY(0)',
					},
				},
				'&:focus': {
					'&::after': {
						transform: 'translateY(0)',
					},
				},
				'&:active': {
					'&::after': {
						transform: 'translateY(0)',
					},
				},
				'&.Mui-disabled': {
					color: 'var(--color-grey30)',
				},
			},
			textPrimary: {
				color: 'var(--color-primary)',
				'&::after': {
					background: 'var(--color-primary)',
				},
				'&:hover,&:focus,&:active': {
					color: 'var(--color-secondary)',
				},
			},
			textSecondary: {
				color: 'var(--color-secondary)',
				'&::after': {
					background: 'var(--color-secondary)',
				},
				'&:hover,&:focus,&:active': {
					color: 'var(--color-primary)',
				},
			},
			textLink: {
				color: 'var(--color-primary)',
				borderColor: 'var(--color-primary)',
				'&:hover,&:focus,&:active': {
					color: 'var(--color-grey30)',
					borderColor: 'var(--color-grey30)',
				},
				'&.emptyCartButton': {
					'--button-primary-size': 'var(--size-medium)',
				},
			},
			textNoLinkGrey: {
				color: '#DEDEDE',
			},
			simpleLink: {
				color: 'var(--color-primary)',
				padding: '0px 0px 2px',
				border: '0px',
				borderBottom: '1px solid',
				borderColor: 'var(--color-primary)',
				minHeight: 'unset',
				'&:hover,&:focus,&:active': {
					color: 'var(--color-grey30)',
					borderColor: 'var(--color-grey30)',
				},
			},
			textGrey: {
				'--button-primary-size': 'var(--size-base)',
			},
			sizeSmall: {
				fontSize: 14,
				padding: '12px 20px',
			},
		},
	},
	MuiBadge: {
		styleOverrides: {
			colorPrimary: {
				borderWidth: '1px',
			},
			standard: {
				minHeight: 15,
				minWidth: 15,
				transform: 'scale(1) translate(4.5px, 0)',
				fontSize: '7px',
			},
		},
	},
}

export default Button
